h1{
    display: flex;
    justify-content: center;
    padding: 1rem;
    font-size: 30px;
    font-weight: bold;
}
.btn{
    background-color: white;
    font-size : 16px;
    padding : 1rem;
    border-radius : 3px;
    border : 1px solid #0001;
    margin-left : 25px;
    /* margin-bottom: 1rem; */
    cursor: pointer;
}
iframe{
    height: 53rem;
}iframe{
    height: 53rem;
}